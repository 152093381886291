/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  height: 144px;
}
#head {
  margin-top: 60px;
}
.service_adults {
  font-size: 20px;
  line-height: 1.2;
  padding: 3px 10px;
}
.square {
  height: calc(50% + 130px);
}
.quicklinks {
  top: 260px;
  transform: translateY(0);
  transition: all 0.7s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.quicklinks.cb-scroll-triggered--active {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .direct-booking {
    top: 62px;
    font-size: 12px;
    padding: 0 12px;
  }
  .direct-booking strong {
    font-size: 38px;
  }
}
@media (max-width: 1199px) {
  h3 {
    font-size: 60px;
    line-height: 1.2;
  }
  .skew {
    font-size: 32px;
    line-height: 1.375;
  }
  .skew:before,
  .skew:after {
    font-size: 80px;
  }
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 1.5%;
  margin-left: 1.5%;
}
.side .part {
  margin-right: 1.5%;
  margin-left: 1.5%;
  width: 97%;
}
.side .tiny {
  width: 47%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 3%;
  margin-left: 3%;
}
.side > .slim .part {
  width: 94%;
}
.side > .slim .tiny {
  width: 94%;
}
div.side {
  width: 100%;
  padding-left: 1.5%;
  padding-right: 1.5%;
}
.maincontent .area div.seam,
.maincontent .area div.flat {
  margin-bottom: 30px;
}
.maincontent .area div.seam div.pict,
.maincontent .area div.flat div.pict {
  width: 45.5%;
  position: absolute;
  top: 35px;
  margin: 0;
}
.maincontent .area div.seam {
  padding-right: 3%;
  padding-left: 51.5%;
}
.maincontent .area div.seam div.pict {
  left: 3%;
}
.maincontent .area div.flat {
  padding-left: 3%;
  padding-right: 51.5%;
}
.maincontent .area div.flat div.pict {
  right: 3%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */